<template>
	<div id="header" v-if="$route.path === '/login' || $route.path === '/forget' || $route.path === '/firmRegister' || $route.path === '/register' || $route.path === '/'? false:true ">
		<div class="title">
			<div class="title_left">您好,欢迎来到<span style="color: #409EFF;">云竞通</span></div>
			
			<div class="title_right">
				
				
				<div class="title_right_title" @click="goLogin" v-if="tokens === null || tokens === undefined">立即登录</div>
				<div class="title_right_title" @click="goMy" v-if="tokens !== null && tokens !== undefined">个人中心</div>
				<div class="title_right_title" @click="onSubmit" v-if="tokens !== null && tokens !== undefined">退出登录</div>
				<!-- <div class="title_right_title" >公告</div> -->
				
			</div>
		</div>
	</div>
</template>

<script>
import {mapMutations,mapState} from 'vuex'
export default {
	data () {
		return{
			tokens:null,
		}
	},
	created() {
		this.tokens = this.$store.state.user.token
	},
	computed:{
		...mapState('user',['token'])
	},
	watch:{
		token:{
			deep:true ,//深度监听
			handler(newValue,oldValue){
				this.tokens = newValue;
			}
		}
	},
	methods:{
		...mapMutations('user',['SET_TOKEN_INFO','REMOVE_TOKEN_INFO']),
		// 立即登录
		goLogin() {
			this.$router.push({
			      path:'/login',
			      query: {
			      	redirect: this.$router.currentRoute.fullPath // router对象自带的属性
			      }
			})
		},
		// 退出登录*********************************
		onSubmit() {
			// localStorage.removeItem('info')
			// localStorage.removeItem('info');
			this.REMOVE_TOKEN_INFO()
			this.$router.push({
				path:'/'
			})
		},
		
		
		// 个人中心
		goMy(){
			this.$router.push({
				path:'/user'
			})
		},
		
	}
}
</script>

<style scoped lang="scss">
#header{
	min-width: 1500px;
	height: 35px;
	background-color: #f6f6f6;
	.title{
		width: 1200px;
		height: 100%;
		// background-color: #f6f6f6;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		position: relative;
		.title_left{
			width: 160px;
			height: 35px;
			font-size: 12px;
			font-weight: 400;
			color: #999;
			line-height: 35px;
			margin-left: 20px;
		}
		.title_right{
			height: 35px;
			display: flex;
			align-items: center;
			.title_right_title{
				padding-right: 6px;
				box-sizing: border-box;
				border-right: 1px solid #ddd;
				margin-right: 6px;
				font-weight: 400;
				color: #999;
				line-height: 24px;
				&:hover{
					cursor: pointer;
					color: #000000;
				}
			}
		}
		.title_center{
			height: 35px;
			width: 380px;
			display: flex;
			align-items: center;
		}
	}
}
</style>
