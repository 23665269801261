<template>
  <div class="progress-bar">
   <!-- <div class="progress" :style="{ width: progress + '%' }"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      progress: 0
    }
  },
  methods: {
    start() {
      this.progress = 0
      this.interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += Math.floor(Math.random() * 10)
        }
      }, 200)
    },
    finish() {
      clearInterval(this.interval)
      this.progress = 100
      setTimeout(() => {
        this.progress = 0
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
// .progress-bar {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 3px;
//   background-color: #f0f0f0;
//   z-index: 9999;
// }

// .progress {
//   height: 100%;
// /*  background-color: #42b983; */
//   background-color: rgb(214, 54, 59);
//   transition: width 0.3s;
// }
</style>
