// import Cookies from 'js-cookie'

const TokenKey = 'info'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}


// 保存token信息

export const getItem = name => {
  return JSON.parse(localStorage.getItem(name))
}
// 向localStorage中设置一项数据 名字为name里面设置值为obj
export const setItem = (name, obj) => {
  localStorage.setItem(name, JSON.stringify(obj))
}
// 删除
export const removeitem = name => {
  localStorage.removeItem(name)
}



// 存储登录的userinfo 所有信息
export const getUserInfo = name => {
  return JSON.parse(localStorage.getItem(name))
}

export const setUserInfo = (name, obj) => {
  localStorage.setItem(name, JSON.stringify(obj))
}

export const removeUserInfo = name => {
  localStorage.removeItem(name)
}

