import {
	getToken,
	setToken,
	removeToken,
	setItem,
	getItem,
	removeitem,
	getUserInfo,
	setUserInfo,
	removeUserInfo
} from '@/utils/auth'
const user = {
	namespaced: true,
	state: {
		// 保存公共数据 在设置vuex中的初值时，先从本地存储中取，如果取不到，则初始为空
		token:getItem('token') || null,
		userInfo:getUserInfo('userinfo') || {}
	},
	mutations: {
		// 登录设置信息
		// SETUSER(state, token) {
		// 	state.token = token
		// },
		// REMOVEUSER(state, token) {
		// 	state.token = ''
		// }
		
		SET_TOKEN_INFO (state,tokenObj) {
			state.token = tokenObj
			// 因为刷新会丢失所以进行持久化 调用storage文件里方法
			setItem('token', tokenObj)
		},
		// 退出删除token
		REMOVE_TOKEN_INFO (state,tokenObj) {
			state.token = tokenObj
			removeitem('token')
		},
	},
	actions: {

	}
}



export default user