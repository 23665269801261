// 统一配置文件
import store from '../store/index.js'
export default {
	// 白名单
	whiteListApi:[
		//不要验证token的 
		'/','/bid','/auction','/price','/enter','/deal','/qiyesub','/forget','/targetmulu','/auctiondetails',
		'/notice','/noticeDetail','/news','/about','/404','/cuowu'
	],
	// 配置秘钥
	secretId:'helloWord',//可以是后端告诉我
	// 接口随机时间
	rand: Math.random(),
	// 图片地址前部分
	url:'https://file.yunfeitong.com.cn/',
	// 首页接口的分页
	// homeLimit: 6,
	// // 首页接口的页码
	// homePage: 1,
	// // 首页接口的type
	// homeType:2
	// 页码
	page:1,
	// 分页
	limit:12,
	// 上传图片地址
	uploadUrl:'https://api.paimai.yunfeitong.com.cn/api/upload',
	headers: {
	   Authorization: "Bearer " + store.state.user.token,
	},
	userId:"",//监听多页面只能登录一个账户
}