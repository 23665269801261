<template>
  <div id="app">
	<div>
		<h-eader></h-eader>
	</div>
    <div>
		<router-view/>
	</div>
	<div>
		<f-ooter></f-ooter>
	</div>
  </div>
</template>

<script>
import hEader from './components/header.vue';
import fOoter from './components/footer.vue';
import {mapMutations,mapState} from 'vuex';
import webConfig from './utils/global.config.js';
export default {
  name: 'App',
  data () {
	  return {
		  hackReset:true,
	  }
  },
  created() {
  	// console.log(this.$router)
  },
  mounted() {
	 
	 var log = localStorage;
	 if (log.info) {
		 var ins = localStorage.getItem('info')
		 if (typeof ins === 'string') {
		 	ins = JSON.parse(ins)
			webConfig.userId = ins.user.token;
		 }
	 }
	 window.addEventListener('visibilitychange', function () {
	 	var info = localStorage.getItem('info')
	 	if (info) {
			if (typeof info === 'string') {
				info = JSON.parse(info)
			}
			if (document.hidden == false && webConfig.userId != info.user.token) {
			    webConfig.userId = info.user.token //只有当初始创建的aaa不等于localStorage里面的userId的时候去覆盖掉这个aaa
				location.reload();
			}
		}
	 })
	 // 关闭浏览器删除缓存localStorage.removeItem('info');
  },
  
  methods:{
	// ...mapMutations('user',['SETUSER','REMOVEUSER']),
  },
  beforeDestroy() {
 	
  },
  beforeUnmount() {
  	
  },
  components:{
	  hEader,
	  fOoter
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
